/* global jQuery, gform, gforms_recaptcha_recaptcha_strings, grecaptcha */
( ( $, gform, grecaptcha, strings ) => {
	/**
	 * Make the API request to Google to get the reCAPTCHA token right before submission.
	 *
	 * @since 1.0
	 *
	 * @param {Object} e The event object.
	 * @return {void}
	 */
	const getToken = ( e ) => {
		const form = $( e.data.form );
		const recaptchaField = form.find( '.ginput_recaptchav3' );
		const dataInput = recaptchaField.find( '.gfield_recaptcha_response' );

		if ( ! dataInput.length || dataInput.val().length ) {
			return;
		}

		e.preventDefault();

		grecaptcha.ready( () => {
			grecaptcha.execute( strings.site_key, { action: 'submit' } )
				.then( ( token ) => {
					if ( token.length && typeof token === 'string' ) {
						dataInput.val( token );
					}

					form.submit();
				} );
		} );
	};

	/**
	 * Add event listeners to the form.
	 *
	 * @since 1.0
	 *
	 * @param {string|number} formId The numeric ID of the form.
	 * @return {void}
	 */
	const addFormEventListeners = ( formId ) => {
		const $form = $( `#gform_${ formId }:not(.recaptcha-v3-initialized)` );
		const $submit = $form.find( `#gform_submit_button_${ formId }` );

		$form.on( 'submit', { form: $form }, getToken );
		$submit.on( 'click', { form: $form }, getToken );

		$form.addClass( 'recaptcha-v3-initialized' );
	};

	/**
	 * The reCAPTCHA handler.
	 *
	 * @since 1.0
	 *
	 * @return {void}
	 */
	const gfRecaptcha = () => {
		const self = this;

		/**
		 * Initialize the Recaptcha handler.
		 *
		 * @since 1.0
		 *
		 * @return {void}
		 */
		self.init = () => {
			self.elements = {
				formIds: self.getFormIds(),
			};
			self.addEventListeners();
		};

		/**
		 * Get an array of form IDs.
		 *
		 * @since 1.0
		 *
		 * @return {Array} Array of form IDs.
		 */
		self.getFormIds = () => {
			const ids = [];
			const forms = document.querySelectorAll( '.gform_wrapper form' );

			forms.forEach( ( form ) => {
				if ( 'formid' in form.dataset ) {
					ids.push( form.dataset.formid );
				} else {
					ids.push( form.getAttribute( 'id' ).split( 'gform_' )[ 1 ] );
				}
			} );

			return ids;
		};

		/**
		 * Add event listeners to the page.
		 *
		 * @since 1.0
		 *
		 * @return {void}
		 */
		self.addEventListeners = () => {
			self.elements.formIds.forEach( ( formId ) => {
				addFormEventListeners( formId );
			} );

			$( document ).on( 'gform_post_render', ( event, formId ) => {
				addFormEventListeners( formId );
			} );
		};

		self.init();
	};

	// Initialize and run the whole shebang.
	$( document ).ready( () => {
		gfRecaptcha();
	} );
} )( jQuery, gform, grecaptcha, gforms_recaptcha_recaptcha_strings );
